import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import Blockquote from "@components/blockquote"
import GlobalQuickLinks from "@components/globalQuickLinks"
import { QuotePanel } from "@components/quotes"

import {
  LearnMoreButton,
  QuickLinkButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const MusicProducerPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Music Producer & Engineer, Label Owner, Songwriter | Jimmy Ether"
        ogDescription="For more than 30 years, Jimmy Ether has been a music producer, recording & mastering engineer, label owner, and songwriter."
        image="https://jimmyether.com/images/music-producer-jimmy-ether.jpg"
        url="https://jimmyether.com/music-producer/"
      />
      <TextBlock
        textPadded
        textLeft={
          <div>
            <h1>Music Producer - Jimmy Ether</h1>
            <p>
              For more than 30 years, Jimmy Ether has been heavily invested in the music industry. As a music producer, recording & mastering engineer, label owner, songwriter, and now print mazaine publisher. He co-owns <a href="https://recordplug.com" target="_blank">Record Plug</a>, a monthly print music magazine covering the Atlanta and Athens, GA music scenes. He runs the <a href="https://headphonetreats.com" target="_blank">Headphone Treats</a> record label and recording studios. And he plays in the bands Loud Humans and Victory Hands as well as several ongoing studio projects. 
            </p>
            <p>
              On YouTube, TikTok, and other social media platform he produces videos on the process of music making. He covers topics on songwritting, recording, mixing, mastering, releasing vinyl, and occasional features on favorite gear.
            </p>
            <p>
              Each day (usually) from 4:30pm to 6pm eastern time look for his <a href="/live-streaming-music-production">music production live streams</a> where you can watch him work, ask questions, or just chat about music.
            </p>
          </div>
        }
        textRight={
          <div>
            <img src="/images/music-producer-jimmy-ether.jpg" alt="Music Producer Jimmy Ether" />
          </div>
        }
      />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>Quick Links</h2>
            <GlobalQuickLinks />
          </div>
        }
      />
      <QuotePanel text="Hello World" person="John Doe" />
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h4>Got a question? Or want to give me a piece of your mind? All good, fill out the form.</h4>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}
export default MusicProducerPage
