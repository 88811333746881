import React from "react"
import { v4 as uuidv4 } from "uuid"
import Slider from "react-slick"
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 7000,
  slidesToScroll: 1,
  draggable: false,
  arrows: false,
  focusOnSelect: false,
}

export default function QuotePanel({ person, title, text, cls }) {
  return (
    <div>
      {title && <h1 className="quote-panel-title">{title}</h1>}
      <Slider {...settings}>
        {testimonials.map((t, i) => (
          <div className={`quote-panel ${cls}`} key={uuidv4()}>
            <div className="testimonials_head">
              <div className="left_line"></div>
              <div className="center_image">
                <FaQuoteLeft className="quote-left" />
              </div>
              <div className="right_line"></div>
            </div>
            <div className="testimonials_txt">
              <img src={t.picture} /><p>{t.text}</p>
              <p>
                {t.name}<br /><a href={t.url} target="_blank">{t.credit}</a>
              </p>
            </div>
            <div className="testimonials_foot">
              <div className="left_line"></div>
              <div className="center_image">
                <FaQuoteRight className="quote-right" />
              </div>
              <div className="right_line"></div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}

QuotePanel.defaultProps = {
  cls: "purple",
  person: "",
  text: "A nice review....",
  title: "",
}

const testimonials = [
  {
    name: "Blake Rainey",
    picture: "/images/testimonials/blake-rainey-the-young-antiques-clockworker.jpg",
    credit: "The Young Antiques. Clockworker produced by Jimmy Ether",
    url: "https://www.allmusic.com/artist/young-antiques-mn0000453690/discography",
    text: `Working with Jimmy has always been a rewarding and productive experience. I've had the pleasure of involving multiple projects with Jimmy behind the board - be it audio recording, audio mixing or mastering - and every time I've come away satisfied with the results.`,
  },
  {
  name: "Matthew Byars ",
  picture: "/images/testimonials/matthew-byars-the-caribbean-remix.jpg",
  credit: "The Caribbean. Remix by Jimmy Ether.",
  url: "https://www.allmusic.com/artist/the-caribbean-mn0000312841/discography",
  text: `Jimmy did a remix from a song off our new album, turned it around immediately, and it was wonderful: clever, unexpected, and wholly original. I couldn't have asked for a better experience--highly recommended!`,
  },
  {
  name: "Bret Alain Phillips",
  picture: "/images/testimonials/bret-alain-phillips-oldman-winter-artic-circle.jpg",
  credit: "Oldman Winter. An Artic Circle mastering by Jimmy Ether",
  url: "https://www.allmusic.com/artist/oldman-winter-mn0002139985",
  text: `I have worked with Jimmy many times over the years. He has always provided me with exceptional service, quick turnaround and a wonderful end product. I hope to work with him again on future projects.`,
  },
  {
  name: "Robbie Horlick",
  picture: "/images/testimonials/robbie-horlick-cassavetes-record-production.jpg",
  credit: "Cassavetes. Record Production by Jimmy Ether",
  url: "https://www.discogs.com/release/14284885-Cassavetes-Funny-Story",
  text: `Jimmy was an excellent producer -- he recorded my band's full length record, providing ample technical experience, ideas, enthusiasm, and creative support.`,
  },
  {
  name: "Paul Melançon",
  picture: "/images/testimonials/paul-melançon-new-insecurities-recording.jpg",
  credit: "Paul Melançon and the New Insecurities. Recordings by Jimmy Ether.",
  url: "http://www.paulmelancon.com/",
  text: `I've worked with Jimmy Ether on a variety of projects and each experience has been a master class in professionalism and skill. Beyond his capability and comfort with the gear and tech, he brings an innate understanding of the creative mind to each project, and the ability to find the heart of the idea and make it sing.`,
  },
  {
  name: "Christo Harris",
  picture: "/images/testimonials/christo-harris-orange-hat-live-albums.jpg",
  credit: "Orange Hat. Live Albums mixing and mastering by Jimmy Ether.",
  url: "",
  text: `Jimmy worked with me to remix and master several projects from my bands back catalog. He has a great ear for mastering and brought new life to our decades-old live performances and studio recordings. Considering the archival nature of the project, it also helps that he has no fear of ancient audio formats!`,
  },
  {
  name: "John Lane",
  picture: "/images/testimonials/john-lane-expo-sea-shells.jpg",
  credit: "Expo. She Sells Seashells mastered by Jimmy Ether.",
  url: "https://expo.bandcamp.com/album/she-sells-seashells",
  text: `Jimmy Ether is a masterful professional. He has mastered a bunch of our albums, and with each project he has blown our minds with the finished product. Jimmy simply has that special ear that you want on the job, particularly when you're too close to the project as musician/composer. His attention to subtleties and detail have few rivals. Add to that, he possesses a warmth and friendliness- not a musical elitist- which makes working with him such a joy. As I've said before elsewhere, he is the epitome of the Southern Gentleman.`,
  },
  {
  name: "Rob Gibson",
  picture: "/images/testimonials/rob-gibson-mister-fusty-mastering.jpg",
  credit: " Mister Fusty. Mastering by Jimmy Ether.",
  url: "https://misterfusty.bandcamp.com/album/the-waiting-room",
  text: `Jimmy mastered a couple of albums of mine and did an excellent job! The expertise and precision of Jimmy's work impressed me and he was professional throughout the process. He brings a lot of creative ideas to whatever he does and for that reason alone I wouldn't hesitate in working with him again.`,
  },
]
